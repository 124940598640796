import dotenv from 'dotenv';
import Cookies from 'universal-cookie';

dotenv.config();

const { REACT_APP_COOKIE_NAME } = process.env;

const cookie = JSON.parse(REACT_APP_COOKIE_NAME);
const cookies = new Cookies();

const readCookie = () => cookies.get(cookie.api);

const removeCookie = () => {
  cookies.remove(cookie.api, {
    domain: cookie.domain,
    path: '/',
  });
  cookies.remove(cookie.application, {
    domain: cookie.domain,
    path: '/',
  });
};

const readCookieByName = (cookieName) => cookies.get(cookieName);
const removeCookieByName = (cookieNames = []) => {
  for (const cookieName of cookieNames) {
    cookies.remove(cookieName, {
      domain: cookie.domain,
      path: '/',
    });
  }
};

export { readCookie, removeCookie, readCookieByName, removeCookieByName };
