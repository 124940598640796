import createPaginationReducer from 'evercheck-utils/lib/paginationReducerFactory';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';
import createEntityTypes from 'evercheck-utils/lib/createEntityTypes';
import combineReducers from 'evercheck-utils/lib/combineReducers';
import { SET_MANUAL_VERIFICATION_MODAL, SET_MANUAL_VERIFICATION_NOTES } from '@ec-admin/shared/constants';

import * as entities from '../entityNames';

const initialModalState = {
  show: false,
  data: {},
};

const manualVerificationQueue = combineReducers({
  entities: fetchReducerFactory({
    entityName: entities.manualVerificationQueue,
    additionalReducers: [
      {
        type: SET_MANUAL_VERIFICATION_NOTES,
        callback: ({ id, notes }, state) => {
          if (state[id]) {
            state[id].notes = notes;
          }
          return state;
        },
      },
    ],
  }),
  pagination: createPaginationReducer(
    createEntityTypes({ entityName: entities.manualVerificationQueue, asObject: true })
  ),
  modal: (state = initialModalState, action) => {
    switch (action.type) {
      case SET_MANUAL_VERIFICATION_MODAL:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  },
});

export default manualVerificationQueue;
