import combineReducers from 'evercheck-utils/lib/combineReducers';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';

import * as entities from '../entityNames';

const creditsAndFeesActions = combineReducers({
  addCreditOrFee: fetchReducerFactory({
    entityName: entities.saveCreditsFees,
  }),
  patchCreditOrFee: fetchReducerFactory({
    entityName: entities.patchCreditsFees,
  }),
  deleteCreditOrFee: fetchReducerFactory({
    entityName: entities.deleteCreditsFees,
  }),
});

export default creditsAndFeesActions;
