import styled from 'styled-components';

export const Layout = styled.div`
  position: absolute;
  width: 53%;
  top: 41%;
  display: flex;

  img {
    margin-left: auto;
  }
`;
