import createPaginationReducer from 'evercheck-utils/lib/paginationReducerFactory';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';
import createEntityTypes from 'evercheck-utils/lib/createEntityTypes';
import combineReducers from 'evercheck-utils/lib/combineReducers';

import * as entities from '../entityNames';

const manualVerificationHistory = combineReducers({
  entities: fetchReducerFactory({
    entityName: entities.manualVerificationHistory,
  }),
  pagination: createPaginationReducer(
    createEntityTypes({ entityName: entities.manualVerificationHistory, asObject: true })
  ),
});

export default manualVerificationHistory;
