import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';
import combineReducers from 'evercheck-utils/lib/combineReducers';

/** START Import reducers  */
import Context, { useGlobalContext } from './Context';
import * as entities from './entityNames';
import auth from './auth/reducers';
import invoices from './invoices/reducers';
import invoiceDetails from './invoiceDetails/reducer';
import pays from './pays/reducers';
import employers from './employers/reducers';
import employerProfile from './employerProfile/reducers';
import manualVerificationQueue from './manualVerificationQueue/reducers';
import manualVerificationActions from './manualVerificationActions/reducers';
import manualVerificationHistory from './manualVerificationHistory/reducers';
import creditsAndFees from './creditsAndFees/reducers';
import creditsAndFeesActions from './creditsAndFeesActions/reducers';
import frequencies from './frequencies/reducers';
import frequenciesActions from './frequenciesActions/reducers';
import states from './states/reducers';
import boards from './boards/reducers';
import professions from './professions/reducers';
import workgroups from './workgroups/reducers';
import workgroupsValues from './workgroupsValues/reducers';
import employerApps from './employerApps/reducers';
import employerAppsSettings from './employerAppsSettings/reducers';
import billingSettings from './billingSettings/reducers';
import customNotifications from './customNotifications/reducers';
import customNotificationsActions from './customNotificationsActions/reducers';

/** END Import reducers  */

const reducer = combineReducers({
  auth,
  credentials: fetchReducerFactory({ entityName: entities.credentials }),
  invoiceDetails,
  invoices,
  pays,
  employers,
  employerApps,
  employerAppsSettings,
  employerProfile,
  manualVerificationQueue,
  manualVerificationActions,
  manualVerificationHistory,
  creditsAndFees,
  creditsAndFeesActions,
  frequencies,
  frequenciesActions,
  states,
  boards,
  professions,
  workgroups,
  workgroupsValues,
  billingSettings,
  customNotifications,
  customNotificationsActions,
});

export const GlobalContext = Context;
export const EntityNames = { ...entities };
export const useAppContext = useGlobalContext;
export default reducer;
