import combineReducers from 'evercheck-utils/lib/combineReducers';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';

import * as entities from '../entityNames';

const customNotificationsActions = combineReducers({
  updateCustomNotificationsStatus: fetchReducerFactory({
    entityName: entities.updateCustomNotificationsStatus,
  }),
  addCustomNotification: fetchReducerFactory({
    entityName: entities.saveCustomNotifications,
  }),
  patchCustomNotification: fetchReducerFactory({
    entityName: entities.patchCustomNotifications,
  }),
});

export default customNotificationsActions;
