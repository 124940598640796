import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import StoreContext from '@ec-admin/store/Context';

const Provider = ({ reducer, children }) => {
  const [store, dispatch] = useReducer(reducer);
  const [state, setState] = useState({ isLoaded: false });

  useEffect(() => {
    dispatch({ type: '@init' });
    setState({ isLoaded: true });
  }, []);

  return <StoreContext.Provider value={[store, dispatch]}>{state.isLoaded ? children : false}</StoreContext.Provider>;
};

Provider.propTypes = {
  reducer: PropTypes.func,
  children: PropTypes.any,
};

export default Provider;
