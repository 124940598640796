import { authentication, authentication_logout, credentials } from '../entityNames';
import { readCookie } from '@ec-admin/core/services/auth/cookie';
import { getToken, getUserInfo } from '@ec-admin/core/services/auth/jwt';

const cookie = JSON.parse(process.env.REACT_APP_COOKIE_NAME);

export const setLoggedInUser = 'SET_LOGGED_USER';

export const login = () => ({
  entityName: authentication,
  url: '/auth/token',
  method: 'POST',
  payload: {
    cookieName: cookie.api,
    token: readCookie(),
  },
});

export const logout = () => ({
  entityName: authentication_logout,
  url: '/auth/logout',
  method: 'POST',
  payload: {
    token: getToken(),
    user: getUserInfo(),
  },
});

export const getCredentials = () => ({
  entityName: credentials,
  url: `/auth/credentials`,
});
