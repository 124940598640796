import createPaginationReducer from 'evercheck-utils/lib/paginationReducerFactory';
import createEntityTypes from 'evercheck-utils/lib/createEntityTypes';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';
import combineReducers from 'evercheck-utils/lib/combineReducers';

import * as entities from '../entityNames';

const { successType: updateCreditOrFeeSuccess } = createEntityTypes({
  entityName: entities.patchCreditsFees,
  asObject: true,
});

export const callback = ({ data }, state) => {
  const newState = { [data.id]: data };

  return { ...state, ...newState };
};

const creditsAndFees = combineReducers({
  entities: fetchReducerFactory({
    entityName: entities.creditsAndFees,
    additionalReducers: [
      {
        type: updateCreditOrFeeSuccess,
        callback,
      },
    ],
  }),
  pagination: createPaginationReducer(
    createEntityTypes({
      entityName: entities.creditsAndFees,
      asObject: true,
    })
  ),
});

export default creditsAndFees;
