import combineReducers from 'evercheck-utils/lib/combineReducers';
import createPaginationReducer from 'evercheck-utils/lib/paginationReducerFactory';
import createEntityTypes from 'evercheck-utils/lib/createEntityTypes';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';
import * as entities from '../entityNames';

export const getUserNotificationGroupCallBack = ({ data }) => {
  return data.items;
};
export const getMessageTextCallBack = ({ data }) => {
  return data.items;
};

const customNotifications = combineReducers({
  entities: fetchReducerFactory({
    entityName: entities.customNotifications,
  }),
  userNotificationGroup: fetchReducerFactory({
    entityName: entities.userNotificationGroups,
    onSuccess: getUserNotificationGroupCallBack,
  }),
  messageText: fetchReducerFactory({
    entityName: entities.messageText,
    onSuccess: getMessageTextCallBack,
  }),
  pagination: createPaginationReducer(
    createEntityTypes({
      entityName: entities.customNotifications,
      asObject: true,
    })
  ),
});

export default customNotifications;
