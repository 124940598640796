export const invoices = 'invoices';
export const invoicesDetails = 'invoiceDetails';
export const billingSettings = 'billingSettings';
export const authentication = 'authentication';
export const authentication_logout = 'authentication_logout';
export const credentials = 'credentials';
export const save_pays = 'save_pays';
export const patch_pays = 'patch_pays';
export const employers = 'employers';
export const manualVerificationQueue = 'manualVerificationQueue';
export const saveManualVerification = 'saveManualVerification';
export const updateManualVerification = 'updateManualVerification';
export const manualVerificationHistory = 'manualVerificationHistory';
export const employersProfile = 'employerProfile';
export const creditsAndFees = 'creditsAndFees';
export const saveCreditsFees = 'save_credits_or_fees';
export const patchCreditsFees = 'patch_credits_or_fees';
export const deleteCreditsFees = 'delete_credits_or_fees';
export const frequencies = 'frequencies';
export const importFrequencies = 'import_frequencies';
export const exportFrequencies = 'export_frequencies';
export const deleteFrequencies = 'delete_frequencies';
export const states = 'states';
export const boards = 'boards';
export const professions = 'professions';
export const workgroups = 'workgroups';
export const workgroupsValues = 'workgroupsValues';
export const employerApps = 'employerApps';
export const employerAppsSettings = 'employerAppsSettings';
export const customNotifications = 'customNotifications';
export const saveCustomNotifications = 'save_custom_notifications';
export const patchCustomNotifications = 'patch_custom_notifications';
export const userNotificationGroups = 'userNotificationGroups';
export const messageText = 'messageText';
export const updateCustomNotificationsStatus = 'updateCustomNotificationsStatus';
export const getProfession = 'getProfession';
