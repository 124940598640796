import createPaginationReducer from 'evercheck-utils/lib/paginationReducerFactory';
import combineReducers from 'evercheck-utils/lib/combineReducers';
import createEntityTypes from 'evercheck-utils/lib/createEntityTypes';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';

import * as entities from '../entityNames';

const { successType: updatePaySuccess } = createEntityTypes({
  entityName: entities.patch_pays,
  asObject: true,
});

const { successType: savePaySuccess } = createEntityTypes({
  entityName: entities.save_pays,
  asObject: true,
});

const paysCallback = ({ data }, state) => {
  return { ...state, ...data };
};

const invoices = combineReducers({
  entities: fetchReducerFactory({
    entityName: entities.invoices,
    initialState: {},
    additionalReducers: [
      {
        type: savePaySuccess,
        callback: paysCallback,
      },
      {
        type: updatePaySuccess,
        callback: paysCallback,
      },
    ],
  }),
  pagination: createPaginationReducer(createEntityTypes({ entityName: entities.invoices, asObject: true })),
});

export default invoices;
