import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Progressbar from 'emerald-ui/lib/Progressbar';

import Provider from '@ec-admin/shared/components/provider';
import App from '@ec-admin/core/App';

import { unregister } from './serviceWorker';

import { default as reducers } from '@ec-admin/store';

const { REACT_APP_URL_BASE = '/'} = process.env;

const Index = () => {
  return (
    <Provider reducer={reducers}>
      <BrowserRouter basename={REACT_APP_URL_BASE}>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

console.log('Environment loaded.');

ReactDOM.render(
  <Suspense fallback={<Progressbar />}>
    <Index />
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
