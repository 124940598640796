import combineReducers from 'evercheck-utils/lib/combineReducers';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';

import * as entities from '../entityNames';
import { createEntityTypes } from '@ec-admin/shared/utils';
import { REQUEST_STATUSES } from '@ec-admin/shared/constants';

const [verifyFetchingType, verifySuccessType, verifyErrorType] = createEntityTypes({
  entityName: entities.saveManualVerification,
});

const initialState = {
  requestStatus: REQUEST_STATUSES.NOT_FETCHED,
};

const verifyManualVerification = (state = initialState, action) => {
  switch (action.type) {
    case verifyErrorType:
      return parseErrorResponse(state, action);
    case verifyFetchingType:
    case verifySuccessType:
      return { ...state, requestStatus: action.requestStatus };
    default:
      return state;
  }
};

const parseErrorResponse = (state, action) => {
  let error = action.error;
  const { response } = error;
  if (response) {
    error = response.data.error || response.data.message;
    if (response.data.apiResponse) {
      error = response.data.apiResponse.data.error || response.data.apiResponse.data.message;
    }
  }
  return {
    ...state,
    error,
    requestStatus: action.requestStatus,
  };
};

export default combineReducers({
  verifyManualVerification,
  updateCredential: fetchReducerFactory({
    entityName: entities.updateManualVerification,
  }),
});
