const { REACT_APP_TOKEN_NAME, REACT_APP_REFRESH_TOKEN_NAME, REACT_APP_ENV, REACT_APP_LOCALSTORAGE_TOKEN } = process.env;

const saveToken = (token) => {
  localStorage.setItem(REACT_APP_TOKEN_NAME, token.access_token);
  localStorage.setItem(REACT_APP_REFRESH_TOKEN_NAME, token.refresh_token);
};

const removeToken = () => localStorage.clear();

const getToken = () => {
  if (REACT_APP_ENV === 'development') {
    return REACT_APP_LOCALSTORAGE_TOKEN;
  }

  return localStorage.getItem(REACT_APP_TOKEN_NAME);
};

const getUserInfo = () => {
  const token = getToken();
  if (token && token.split('.').length === 3) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('-', '/');
    return JSON.parse(window.atob(base64));
  }
};

export { saveToken, removeToken, getToken, getUserInfo };
