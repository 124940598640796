import createEntityTypes from 'evercheck-utils/lib/createEntityTypes';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';
import map from 'lodash/map';
import omit from 'lodash/omit';

import * as entities from '../entityNames';
const { successType: updatePaySuccess } = createEntityTypes({
  entityName: entities.patch_pays,
  asObject: true,
});

const { successType: savePaySuccess } = createEntityTypes({
  entityName: entities.save_pays,
  asObject: true,
});

export const callback = ({ data }, state) => {
  const fee = map(data).shift();
  const pays = fee.pays;

  return {
    ...state,
    data: { ...state.data, fee: { ...omit(fee, 'pays') }, pays },
  };
};

export default fetchReducerFactory({
  entityName: entities.invoicesDetails,
  additionalReducers: [
    {
      type: savePaySuccess,
      callback,
    },
    {
      type: updatePaySuccess,
      callback,
    },
  ],
});
