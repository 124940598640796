import axios from 'axios';
import { REQUEST_STATUSES } from '@ec-admin/shared/constants';
import createEntityTypes from 'evercheck-utils/lib/createEntityTypes';
import { getToken } from '@ec-admin/core/services/auth/jwt';
import { downloadFile } from '@ec-admin/shared/utils';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const setAxiosHeaderToken = () => {
  let Undefined;
  axios.defaults.headers.common['Authorization'] = getToken() ? `Bearer ${getToken()}` : Undefined;
};

const executeCall = async (dispatch, { entityName, url, method = 'GET', params = {}, payload = {} }) => {
  if (!axios.defaults.headers.common['Authorization']) {
    setAxiosHeaderToken();
  }

  /* START - generate types automatically */
  const [onFetchingType, onSuccessType, onErrorType] = createEntityTypes({
    entityName,
  });
  /* END - generate types automatically */

  try {
    const fetchingData = {
      data: {},
      requestStatus: REQUEST_STATUSES.FETCHING,
      error: null,
    };

    dispatch({ type: onFetchingType, ...fetchingData });
    const requestConfig = {
      url,
      method,
      params,
      data: payload,
    };
    const isExport = url.includes('/export');
    if (isExport) {
      requestConfig.responseType = 'blob';
    }

    const { data, headers } = await axios.request(requestConfig);

    const successData = {
      requestStatus: REQUEST_STATUSES.SUCCESSFUL,
      error: null,
    };

    if (isExport) {
      const filename = (headers['content-disposition'] || '').split('"')[1] || `Export_${Date.now()}.xlsx`;
      downloadFile(data, filename, headers['content-type']);
      return dispatch({ type: onSuccessType, ...successData });
    }

    return dispatch({ type: onSuccessType, ...successData, data });
  } catch (error) {
    const errorData = {
      data: {},
      requestStatus: REQUEST_STATUSES.FAILED,
      error,
    };

    return dispatch({ type: onErrorType, ...errorData });
  }
};

export { executeCall };
