export const OFFSET = 0;
export const LIMIT = 10;
export const ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const DEFAULT_PARAMS = {
  limit: 50,
  offset: 0,
};

export const MV_QUEUE_DEFAULT_PARAMS = {
  ...DEFAULT_PARAMS,
  sort: 'profession.name',
};

export const MV_HISTORY_DEFAULT_PARAMS = {
  ...DEFAULT_PARAMS,
  sort: '-processedAt',
};

export const REQUEST_STATUSES = {
  NOT_FETCHED: 'notFetched',
  FETCHING: 'fetching',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
};

export const GENERAL_ROUTES = {
  root: '/',
  switchApp: '/switch',
  dashboard: '/dashboard',
  employers: '/employers',
  manualVerifications: '/manual-verifications',
  invoices: '/invoices',
  credits: '/credits-fees',
};

export const INVOICE_ROUTES = {
  details: `${GENERAL_ROUTES.employers}/:employerId/invoices/:invoiceId`,
};

export const EMPLOYER_ROUTES = {
  profile: `${GENERAL_ROUTES.employers}/:employerId`,
};

export const MANUAL_VERIFICATION_ROUTES = {
  activeTab: `${GENERAL_ROUTES.manualVerifications}/:activeTab?`,
  queue: `${GENERAL_ROUTES.manualVerifications}/queue`,
  history: `${GENERAL_ROUTES.manualVerifications}/history`,
};

export const MDASH = '—';
export const HELPDESK_ROLE = 'HELPDESK';
export const MYSQL_DATE = 'YYYY-MM-DD';
export const PREFIXES = { ACCOUNT_NUMBER: '60-' };

export const CREDITS_AND_FEES_FILTERS = {
  TYPE: ['all', 'credit', 'fee'],
  RECURRING: [
    { value: 'all', view: 'all' },
    { value: '1', view: 'Yes' },
    { value: '0', view: 'No' },
  ],
};

export const FREQUENCIES_FILTERS = {
  TYPE: ['stateId', 'boardId', 'professionId'],
};

export const CREDITS_AND_FEES_MODAL = {
  TYPE: ['credit', 'fee'],
  RECURRING: [
    { value: 1, key: 'Yes' },
    { value: 0, key: 'No' },
  ],
};

export const adminPrefix = '30-';

export const appsNames = {
  education: {
    app: 'education',
    name: 'Education app',
  },
  cebroker: {
    app: 'cebroker',
    name: 'CE Broker app',
  },
};

export const SERVICE_TYPES = {
  MONTHLY_CHARGE: { label: 'Monthly', value: 'MONTHLY_CHARGE' },
  NONE: { label: '-', value: null },
};

export const ACTIVATE_SENDEMAIL_CEB_MESSAGE = 'Activate CE broker and send welcome email';

export const SET_MANUAL_VERIFICATION_MODAL = 'SET_MANUAL_VERIFICATION_MODAL';
export const SET_MANUAL_VERIFICATION_NOTES = 'SET_MANUAL_VERIFICATION_NOTES';

export const EMPLOYEE = 'EMPLOYEE';

export const NOTIFICATIONS_FILTERS = {
  STATUS: [
    { value: 'true', view: 'Active' },
    { value: 'false', view: 'Inactive' },
  ],
};

export const NOTIFICATIONS_ACTIONS = {
  ACTIVATE: 'Activate',
  INACTIVATE: 'Inactivate',
};

export const CUSTOM_NOTIFICATIONS_FREQUENCIES = [
  { value: 'DAILY', view: 'Daily' },
  { value: 'WEEKLY', view: 'Weekly' },
  { value: 'BIWEEKLY', view: 'Bi-Weekly' },
  { value: 'MONTHLY', view: 'Montly' },
];

export const CUSTOM_NOTIFICATION_RANGES = {
  PRIOR: {
    view: 'prior to Expiration',
    template: (days) => `${days} days prior to Expiration`,
  },
  WITHIN: {
    view: 'withn the next ... days',
    template: (days) => `Within the next ${days} days`,
  },
  AFTER: {
    view: 'after expiration',
    template: (days) => `${days} days after Expiration`,
  },
  ONDATE: {
    template: () => `On date of expiration`,
  },
};

export const CUSTOM_NOTIFICATION_RANGES_TEXT = {
  PRIOR: 'PRIOR',
  WITHIN: 'WITHIN',
  AFTER: 'AFTER',
  ONDATE: 'ONDATE',
};

export const RESTRICT_PROFESSIONS_COPY = `By checking this box, you'll restrict the notification to only go out to a subset of EverCheck professions. If you want the notification to go out for all LCRs in the account, do not check this box.`;

export const PROFESSION_FILTERS = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
};
