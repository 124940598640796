import 'emerald-ui/lib/styles-ec.css';
import 'emerald-ui/lib/dark-theme.css';
import React, { lazy, Suspense, memo, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Progressbar from 'emerald-ui/lib/Progressbar';

import LoadingState from '@ec-admin/shared/components/LoadingState';

import { useAppContext } from '@ec-admin/store';
import { executeCall } from '@ec-admin/store/apiCalls';

import { isFetching, hasRequestFail } from '@ec-admin/shared/utils';
import { getToken } from '@ec-admin/core/services/auth/jwt';
import { logout } from '@ec-admin/core/services/auth/auth';

import { GENERAL_ROUTES, REQUEST_STATUSES, HELPDESK_ROLE } from '@ec-admin/shared/constants';
import { login, setLoggedInUser } from '@ec-admin/store/auth';

const Home = lazy(() => import('../../scenes/Home'));
const LandingPage = lazy(() => import('../../scenes/LandingPage'));

const App = () => {
  const [state, dispatch] = useAppContext();
  const auth = state.auth;

  useEffect(() => {
    if (!getToken() && auth.requestStatus === REQUEST_STATUSES.NOT_FETCHED) {
      executeCall(dispatch, login());
    }

    if (getToken() && auth.requestStatus !== REQUEST_STATUSES.SUCCESSFUL) {
      dispatch({ type: setLoggedInUser });
    }

    if (
      hasRequestFail(auth.requestStatus) ||
      (auth && auth.loggedInUser && auth.loggedInUser.scope !== HELPDESK_ROLE)
    ) {
      logout();
    }
  }, [auth]);

  return (
    <>
      {isFetching([auth.requestStatus]) ? (
        <LoadingState />
      ) : (
        <Suspense fallback={<Progressbar continuous />}>
          <Switch>
            <Route exact path={GENERAL_ROUTES.switchApp} component={LandingPage} />
            <Route exact path={GENERAL_ROUTES.root} component={Home} />
            <Route component={Home} />
          </Switch>
        </Suspense>
      )}
    </>
  );
};

export default memo(App);
