import createPaginationReducer from 'evercheck-utils/lib/paginationReducerFactory';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';
import createEntityTypes from 'evercheck-utils/lib/createEntityTypes';
import combineReducers from 'evercheck-utils/lib/combineReducers';

import * as entities from '../entityNames';

const employers = combineReducers({
  entities: fetchReducerFactory({
    entityName: entities.employers,
  }),
  pagination: createPaginationReducer(createEntityTypes({ entityName: entities.employers, asObject: true })),
});

export default employers;
