import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
import axiosBuildURL from 'axios/lib/helpers/buildURL';
import mapValues from 'lodash/mapValues';
import qs from 'querystring';
import momentTz from 'moment-timezone';
import moment from 'moment';
import values from 'lodash/values';
import axios from 'axios';

import { REQUEST_STATUSES, STANDARD_DATE } from 'evercheck-utils/constants';
import { getToken } from '@ec-admin/core/services/auth/jwt';
import * as utils from '@ec-admin/shared/utils/index';

/**
 * Returns a boolean that indicates whether a request is loading.
 * This helper function is useful to determine whether a Spinner should be shown.
 * For the sake of simplicity, both the LOADING and the NOT_LOADED statuses mean
 * a request is loading.
 *
 * @param {string} fetchStatus
 *
 * @returns {boolean}
 */
export const isFetching = (fetchStatus = '') => {
  const newFetchStatus = isArray(fetchStatus) ? fetchStatus.map((status) => ({ status })) : [{ status: fetchStatus }];

  return !isEmpty(
    find(newFetchStatus, { status: REQUEST_STATUSES.NOT_FETCHED }) ||
      find(newFetchStatus, { status: REQUEST_STATUSES.FETCHING })
  );
};

/**
 * Returns a boolean that indicates if a request has fail.
 * This helper function is useful to determinate whether one or more request has faild
 *
 * @param {string} or {string array}  fetchStatus
 *
 * @returns {boolean}
 */

export const hasRequestFail = (fetchStatus = '') => {
  const newFetchStatus = isArray(fetchStatus) ? fetchStatus.map((status) => ({ status })) : [{ status: fetchStatus }];

  return !isEmpty(find(newFetchStatus, { status: REQUEST_STATUSES.FAILED }));
};

export const createEntityTypes = ({ entityName, asObject = false }) => {
  const fetchingType = `FETCHING_${entityName.toUpperCase()}`;
  const successType = `FETCH_${entityName.toUpperCase()}_SUCCESS`;
  const errorType = `FETCH_${entityName.toUpperCase()}_ERROR`;
  const clearType = `CLEAR_${entityName.toUpperCase()}_PAGINATION`;

  return asObject
    ? { fetchingType, successType, errorType, clearType }
    : [fetchingType, successType, errorType, clearType];
};

/**
 * Returns a boolean that indicates if a request has fail.
 * This helper function is useful to determinate whether one or more request has faild
 *
 * @param {string} or {string array}  fetchStatus
 *
 * @returns {boolean}
 */

export function fetchReducerFactory({
  entityName,
  initialState = { requestStatus: REQUEST_STATUSES.NOT_FETCHED },
  onFetching,
  onSuccess,
  onError,
  additionalReducers = [],
}) {
  if (!entityName && !(String instanceof entityName)) {
    throw TypeError('Invalid parameter entityName');
  }

  for (const argument of Object.keys(arguments[0]).filter((arg) => arg.startsWith('on'))) {
    if (argument && !isFunction(arguments[0][argument])) {
      throw TypeError(`Invalid function ${argument}`);
    }
  }

  const [FETCHING, SUCCESS, ERROR] = createEntityTypes({ entityName });
  const allActions = [
    { type: FETCHING, callback: onFetching },
    { type: SUCCESS, callback: onSuccess },
    { type: ERROR, callback: onError },
    ...additionalReducers,
  ];

  return (state = initialState, action) => {
    const payload = action.payload ? action.payload : omit(action, 'type');
    for (const act of allActions) {
      if (act.type === action.type) {
        return act.callback ? act.callback(payload, state) : payload;
      }
    }
    return state;
  };
}

/**
 * Converts a query string to an object
 *
 * @param {string} [search='']  'offset=0&limit=10' or '?offset=0&limit=10'
 * @returns {object}  {offset: 0, limit: 10}
 */
export const convertQueryStringToAnObject = (search = '') => {
  const questionMarkPosition = search.indexOf('?');
  const queryString = questionMarkPosition === -1 ? search : search.substring(questionMarkPosition + 1, search.length);

  return mapValues(qs.parse(queryString), (value) => {
    let result;
    try {
      result = JSON.parse(value);
    } catch (error) {
      result = value;
    }
    return result;
  });
};

/**
 * Converts an object to a query string
 *
 * @param {object} [source={}]   {offset:0, limit:10}
 * @param {boolean} withQuestionCharacter
 * @returns {string}  '?offset=0&limit=10' or 'offset=0&limit=10'
 */
export const convertObjectToQueryString = (source = {}, withQuestionCharacter = true) => {
  const queryString = axiosBuildURL('', source);
  return withQuestionCharacter ? queryString : queryString.slice(1);
};

/** Formats date using a given format
 *
 * @param {Date|string} date 17-MAR-17
 * @param {string} outputFormat MMM D YYYY
 * @param {string} inputFormat DD-MMM-YY
 *
 * @returns {string} MAR 17 2017
 */
export const formatDate = (date = new Date(), outputFormat = 'MMM D YYYY', inputFormat) => {
  return momentTz.tz(date, inputFormat, 'America/New_York').format(outputFormat);
};

export const ECDate = moment;

/**
 * Returns all words capitalized
 *
 * @param {string} value, string to transform
 */
export const startCase = (value) => {
  return value ? `${value}`.toLowerCase().replace(/\b\w/g, (string) => string.toUpperCase()) : '';
};

/**
 * Returns the number of active filters in Table
 *
 * @param {object} filters
 * @returns {number}
 */
export const countFilters = (filters) => {
  return values(filters).filter((value) => value && value !== 'active').length;
};

/**
 * Returns a url that points to the related endpoint to export data
 *
 * @param {string} entity name of the resource to be exported
 * @param {object} urlParams object with all the params that the export endpoint should receive
 * @param {string} api
 *
 * @returns {string}
 */
export const buildExportURL = (entity, urlParams, api = 'econe') => {
  const { REACT_APP_ECE_API_URL, REACT_APP_EC_ONE_API_URL } = process.env;
  const API_URL = api === 'ece' ? REACT_APP_ECE_API_URL : REACT_APP_EC_ONE_API_URL;
  const URL = `${API_URL}/${entity}/export`;
  return axiosBuildURL(URL, { ...urlParams, token: getToken() });
};

export const downloadExportFile = async (entity, urlParams, api = 'econe') => {
  const { REACT_APP_ECE_API_URL, REACT_APP_EC_ONE_API_URL } = process.env;
  const API_URL = api === 'ece' ? REACT_APP_ECE_API_URL : REACT_APP_EC_ONE_API_URL;
  const URL = `${API_URL}/${entity}/export`;

  const { data, headers } = await axios.request({
    url: axiosBuildURL(URL, { ...urlParams }),
    method: 'GET',
    responseType: 'blob',
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
  utils.downloadFile(data, `${headers['content-disposition']}`.split('"')[1], headers['content-type']);
};

export const downloadFile = (data, filename = `Export_${moment().format(STANDARD_DATE.replace(/[/]/g, ''))}`, mime) => {
  const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

export const getCurrentPage = (offset = 0, limit = 0) => {
  if (limit <= 0) {
    return 1;
  }
  return parseInt(offset / limit, 10) + 1;
};

export const formatAsCurrency = (value = 0) => {
  const parsedFloatValue = parseFloat(value || 0);
  return parsedFloatValue.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

/**
 * Returns the string date in the format used by Truevault based in a moment object
 *
 * @param {string} string, moment object with the date
 *
 * @returns {string}
 */
export const replaceEspecialCharacter = (string) => {
  return string ? `${string.replace(/_/g, ' ')}` : '';
};

/**
 * Returns the first letter in uppercase and replaces underscore by space.
 *
 * @param {string} value, string to transform
 */
export const capitalize = (value) => {
  if (isEmpty(value)) {
    return '';
  }
  const word = value[0].toUpperCase() + value.slice(1).toLowerCase();
  return replaceEspecialCharacter(word);
};

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

export const formPost = (path, params, method) => {
  const newMethod = method || 'post'; // Set method to post by default if not specified.

  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement('form');
  form.setAttribute('method', newMethod);
  form.setAttribute('action', path);

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

export const getRequestStatus = (entity = {}) => {
  if (!('requestStatus' in entity) && !('entities' in entity)) {
    return 'notFetched';
  }

  return entity.requestStatus || entity.entities.requestStatus;
};
