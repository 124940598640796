import createPaginationReducer from 'evercheck-utils/lib/paginationReducerFactory';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';
import createEntityTypes from 'evercheck-utils/lib/createEntityTypes';
import combineReducers from 'evercheck-utils/lib/combineReducers';

import * as entities from '../entityNames';

const manualVerificationFrequencies = combineReducers({
  entities: fetchReducerFactory({
    entityName: entities.frequencies,
  }),
  pagination: createPaginationReducer(createEntityTypes({ entityName: entities.frequencies, asObject: true })),
});

export default manualVerificationFrequencies;
