import { setLoggedInUser } from './';
import { createEntityTypes } from '@ec-admin/shared/utils';
import { saveToken, getUserInfo, removeToken } from '@ec-admin/core/services/auth/jwt';
import { removeCookie } from '@ec-admin/core/services/auth/cookie';
import { authentication, authentication_logout } from '../entityNames';
import { REQUEST_STATUSES } from '@ec-admin/shared/constants';
const { REACT_APP_ACCOUNTS_LOGOUT } = process.env;

const [authFetchingType, authSuccessType, authErrorType] = createEntityTypes({
  entityName: authentication,
});

const [logoutFetchingType, logoutSuccessType, logoutErrorType] = createEntityTypes({
  entityName: authentication_logout,
});

const initialState = {
  requestStatus: REQUEST_STATUSES.NOT_FETCHED,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setLoggedInUser:
      return {
        ...state,
        requestStatus: REQUEST_STATUSES.SUCCESSFUL,
        loggedInUser: getUserInfo(),
      };
    case authSuccessType: {
      const { access_token, refresh_token } = action.data;
      saveToken({ access_token, refresh_token });
      return {
        ...state,
        requestStatus: action.requestStatus,
        loggedInUser: getUserInfo(),
      };
    }
    case logoutSuccessType:
      removeToken();
      removeCookie();
      return window.location.replace(`${REACT_APP_ACCOUNTS_LOGOUT}`);
    case logoutErrorType:
    case logoutFetchingType:
    case authFetchingType:
    case authErrorType:
      return {
        ...state,
        error: action.error,
        requestStatus: action.requestStatus,
      };
    default:
      return state;
  }
};

export default reducer;
