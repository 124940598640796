import dotenv from 'dotenv';
import axios from 'axios';

import { removeCookie } from './cookie';
import { removeToken, getToken } from './jwt';
import { formPost } from '@ec-admin/shared/utils';

dotenv.config();

const { REACT_APP_ACCOUNTS_LOGOUT, REACT_APP_ACCOUNTS_LOGIN, REACT_APP_EC_ADMIN_URL, REACT_APP_API_BASE_URL } =
  process.env;

const logout = () => {
  axios.post(`${REACT_APP_API_BASE_URL}/auth/logout`, {
    token: getToken(),
  });
  removeToken();
  removeCookie();
  window.location.replace(`${REACT_APP_ACCOUNTS_LOGOUT}`);
};

const relogin = () => {
  removeToken();
  const returnUrl = encodeURIComponent(window.location.href);
  window.location.replace(`${REACT_APP_ACCOUNTS_LOGIN}?ReturnUrl=${returnUrl}`);
};

const switchToClassic = async (EmployerId = 0) => {
  formPost(REACT_APP_EC_ADMIN_URL, { RememberMe: false, EmployerId });
};

export { logout, relogin, switchToClassic };
