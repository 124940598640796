import combineReducers from 'evercheck-utils/lib/combineReducers';
import fetchReducerFactory from 'evercheck-utils/lib/fetchReducerFactory';

import * as entities from '../entityNames';

const frequenciesActions = combineReducers({
  importFrequencies: fetchReducerFactory({
    entityName: entities.importFrequencies,
  }),
  exportFrequencies: fetchReducerFactory({
    entityName: entities.exportFrequencies,
  }),
  deleteFrequencies: fetchReducerFactory({
    entityName: entities.deleteFrequencies,
  }),
});

export default frequenciesActions;
