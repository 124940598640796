import * as entityNames from '../entityNames';
import combineReducers from '../combineReducers';
import { REQUEST_STATUSES } from '@ec-admin/shared/constants';
import createEntityTypes from 'evercheck-utils/lib/createEntityTypes';

const initialState = {
  entities: {},
  requestStatusByWorkgroup: {},
};

function createWorkgroupValuesReducer({ successType, errorType, fetchingType, clearType } = {}) {
  function requestStatusByWorkgroup(state = initialState.requestStatusByWorkgroup, { type, workgroupId = null }) {
    switch (type) {
      case fetchingType: {
        return workgroupId ? { ...state, [workgroupId]: REQUEST_STATUSES.FETCHING } : state;
      }
      case successType: {
        return workgroupId ? { ...state, [workgroupId]: REQUEST_STATUSES.SUCCESSFUL } : state;
      }
      case errorType: {
        return workgroupId ? { ...state, [workgroupId]: REQUEST_STATUSES.FAILED } : state;
      }
      default:
        return state;
    }
  }

  function entities(state = initialState.entities, action) {
    switch (action.type) {
      case successType: {
        const { result, workgroupId } = action;
        return workgroupId ? { ...state, [workgroupId]: result } : state;
      }
      default:
        return state;
    }
  }

  return function workgroupValues(state = initialState, action) {
    switch (action.type) {
      case fetchingType:
      case errorType:
      case successType: {
        return combineReducers({
          entities,
          requestStatusByWorkgroup,
        })(state, action);
      }
      case clearType:
        return initialState;
      default:
        return state;
    }
  };
}

export default createWorkgroupValuesReducer(
  createEntityTypes({
    entityName: entityNames.workgroupsValues,
    asObject: true,
  })
);
