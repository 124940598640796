import React from 'react';
import Progressbar from 'emerald-ui/lib/Progressbar';

import { ECLoading } from '@ec-admin/assets/Images';
import { Layout } from './LoadingState.styled.js';

const LoadingState = () => {
  return (
    <>
      <Progressbar continuous />
      <Layout>
        <img src={ECLoading} alt="loading" />
      </Layout>
    </>
  );
};

export default LoadingState;
