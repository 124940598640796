import combineReducers from '../combineReducers';
import { fetchReducerFactory } from '@ec-admin/shared/utils';
import * as entities from '../entityNames';

const professions = combineReducers({
  entities: fetchReducerFactory({ entityName: entities.professions }),
  getByIds: fetchReducerFactory({ entityName: entities.getProfession }),
});

export default professions;
