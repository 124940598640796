import combineReducers from '../combineReducers';
import { fetchReducerFactory } from '@ec-admin/shared/utils';
import * as entities from '../entityNames';

const pays = combineReducers({
  createPay: fetchReducerFactory({ entityName: entities.save_pays }),
  patchPay: fetchReducerFactory({ entityName: entities.patch_pays }),
});

export default pays;
